export const allSongs = [
    { 'title': 'Wäihe', 'file': 'https://dl.dropbox.com/s/gw8hufsjwh4qglb/1_W%C3%A4ihe%20%28prod.%20Rak%20Ror%29.wav' },
    { 'title': 'Tunnel', 'file': 'https://dl.dropbox.com/s/nrocw6ddj9k91i0/2_Tunnel.wav' },
    { 'title': 'Weischwas II', 'file': 'https://dl.dropbox.com/s/3gup5uxa0sj7hir/3_Weischwas%20II%20%28Dir%C3%A4ktufnahm%29.wav' },
    { 'title': 'Ned d Frog', 'file': 'https://dl.dropbox.com/s/j085h3lz22exkyh/4_Ned%20d%20Frog.wav' },
    { 'title': 'Perfekt', 'file': 'https://dl.dropbox.com/s/v36drp8j1na0h8k/5_Perfekt.wav' },
    { 'title': 'Gedanke Architektur', 'file': 'https://dl.dropbox.com/s/agegspduau0soli/Gedankearchitektur_cut_for_Spotify.wav' },
    { 'title': 'Schönheit', 'file': 'https://www.dropbox.com/scl/fi/i0jyvecpkd8wfsski2apw/SCH-NHEIT-DeClicked-StreamingMaster-02.mp3?rlkey=g4uwl2kd5tqqn2iy0nxnyiic3&dl=1' },
    { 'title': 'Vertroue & Losloh', 'file': 'https://www.dropbox.com/scl/fi/7vnhossdqlc482h78fuqw/Vertroue-ond-Losloh-SpotifyCut.mp3?rlkey=w7tmcy4isfmqr9ow0veu8jbkd&dl=1' },
    { 'title': 'Läbe', 'file': 'https://dl.dropbox.com/s/1j9pfvf2c0fyl3q/DON%20FRISO%20sL%C3%A4be%20StreamingLevelMaster_SPOTIFYCUT.wav' },
    { 'title': '&', 'file': 'https://www.dropbox.com/scl/fi/6zlk3wfplxkft3od886mc/ond_SpotifyCut.mp3?rlkey=2ziy7i3wgbidxq1o38ktxukno&dl=1' },
    { 'title': 'Tod', 'file': 'https://www.dropbox.com/scl/fi/wri1igs3frnbba92q8t7k/TOD-DeClicked-StreamingMaster02.mp3?rlkey=odqiivdp0h9pjx6xcs8fs7tqn&dl=1' },
]

export const liveShows = [
    { 'date': new Date("2022-10-16"), 'title': "Plattentaufe Achterbahn EP, Schüür (LU)", },
    { 'date': new Date("2022-11-24"), 'title': "There Are Worse Bands Festival, Oldtown record store (LU)", },
    { 'date': new Date("2023-02-14"), 'title': "Weindegustation zum Valentinstag, Osteria Valpo (LU)", 'link': "https://otrs.rocks/otrs-event/mia-buffa-valentina/" },
    { 'date': new Date("2023-04-08"), 'title': "Gasse & Kneipe Tour 23, Sopranos (LU)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2023-04-29"), 'title': "Gasse & Kneipe Tour 23, Am Markt (Winti)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2023-05-06"), 'title': "Gasse & Kneipe Tour 23, Cargo Bar (BS)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2023-05-19"), 'title': "Gasse & Kneipe Tour 23 (Biel)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2023-05-20"), 'title': "Gasse & Kneipe Tour 23, Les Amis (Bern)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2023-05-20"), 'title': "Abfahrt nach Auffahrt - Rocknight, Kulturmühle (Horw)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2023-06-09"), 'title': "Support bei Club Arielle Plattentaufe, Treibhaus (LU)", 'link': "https://www.petzi.ch/de/events/52690-treibhaus-club-arielle-ep-taufe/" },
    { 'date': new Date("2023-07-14"), 'title': "Mitternachts-konzert, Schüür (LU)", 'link': "https://www.schuur.ch/programm/events/event-details/don-friso-14-07-2023-konzerthaus-schuur-luzern/" },
    { 'date': new Date("2023-07-22"), 'title': "Festival Wagenplatz Hinterschlund (Kriens)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2023-10-13"), 'title': "HaS Club Festival (Hausen am Albis)", 'link': "https://www.hasclub.ch/de/events" },
    { 'date': new Date("2023-11-20"), 'title': "There Are Worse Bands Festival, Bourbaki (LU)", 'link': "http://lumber-jack.ch/" },
    { 'date': new Date("2023-11-11"), 'title': "Support für Brandhärd, Galvanik (ZG)", 'link': "https://galvanik-zug.ch/programm/23_11_11-brandhaerd" },
    { 'date': new Date("2024-05-17"), 'title': "Plattentaufe Läbe & Tod EP, Kino Riffraff (ZH))", 'link': "https://eventfrog.ch/de/p/konzert/hip-hop-rap/don-friso-plattentaufe-laebe-tod-7165691807421953642.html#ticket-container" },
    { 'date': new Date("2024-05-22"), 'title': "Sommernachtsfest, Berner Fachhochschule (Biel)", 'link': "https://eventfrog.ch/de/p/konzert/hip-hop-rap/don-friso-plattentaufe-laebe-tod-7165691807421953642.html#ticket-container" },
    { 'date': new Date("2023-11-24"), 'title': "Justizvollzugsanstalt - JVA, für Häftlinge (Lenzburg)", 'link': "https://eventfrog.ch/de/p/konzert/hip-hop-rap/don-friso-plattentaufe-laebe-tod-7165691807421953642.html#ticket-container" },
    { 'date': new Date("2024-08-31"), 'title': "There Are Worse Bands Festival Kick-Off (Luzern)", 'link': "https://www.instagram.com/donfriso/" },
    { 'date': new Date("2024-11-07"), 'title': "Les Amis (Bern)", 'link': "https://www.instagram.com/donfriso/" },
    //{ 'date': new Date("2024-10-06"), 'title': "Solo Artist Night, Cargo Bar (BS)", 'link': "" },
]